import React, {useEffect, useState} from "react"
import moment from "moment/moment";
import {clone, cloneDeep, debounce, find, isEmpty, isNull, truncate} from "lodash";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {Archive, Cancel, Check, Clear, DeleteOutline, MoreHoriz, Summarize, Visibility} from "@mui/icons-material";
import {Box, Button, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Stack, SvgIcon} from "@mui/material";
import {ColumnFiltersState, createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";

import {useStores} from "models";
import {BasicTable, FilterRequestTable, MenuIcon, ModalConfirm} from "components";
import {countries, globalAction, optionsTranslation} from "utils";
import {IconEdit} from "assets/images";
import {findRoute} from "constants/routes";
import {ApproveReject, ApproveRejectButton} from "modules";
import {cuppingProtocols, formatDate, sampleTypes, specieses, viewSamples} from "constants/form"
import { saveString } from 'utils/storage';
import { HEADER_SELECTOR_SAMPLE_STORAGE_KEY } from 'config/env';

import {EmptySamples} from "../index";
import { FilterSelect } from "components/filter-request-table/components/filter-component";
import './sample-table.css';
import { AutocompleteSelect } from "components/filter-request-table/components/autocomplete-component";

const columnHelper = createColumnHelper<any>()

const RenderMenuItems = observer((props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    cuppingSessionStore: { getCuppingSession },
    sampleStore: { setValue: setSampleValue },
    reviewSampleStore: { samples, removeSample },
    notificationStore
  } = useStores()
  const {
    id, uniqueToken, cuppingSessionUniqueToken, canSeeReport, canDeleteSample
  } = props.row
  const { isReview, setOpenConfirm, setConfirmAction, setDescription } = props

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const onAction = (token: string, id: string, link: string) => {
    globalAction(notificationStore, {
      action: async () => await getCuppingSession(token),
      afterAction: () => {
        setSampleValue('selectedSample', id)
        navigate(link)
      }
    })
  }

  const onSeeReport = async (token: string, id: string) => {
    if (!isReview) {
      navigate(`/sample-report/${token}`)
      return
    }

    const sample = find(samples, ['id', id])
    if (!sample) return

    onAction(token, id, `/sample-report/${sample.uniqueToken}`)
  }

  const onRemoveSample = async (id: string, isFully = false) => {
    setOpenConfirm(false)
    globalAction(notificationStore, {
      action: async () => await removeSample(id, isFully)
    })
  }

  if (!isReview) {
    return (
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => onSeeReport(uniqueToken, id)}
        startIcon={<Summarize />}
      >
        {t('sample.report')}
      </Button>
    )
  }

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => setAnchorMenu(event.currentTarget)}
      >
        <MoreHoriz />
      </IconButton>

      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={() => navigate(`/samples/${uniqueToken}/details`)}>
          <MenuIcon><Visibility /></MenuIcon>
          {t('sample.samples.button.detail')}
        </MenuItem>
        <MenuItem onClick={() => navigate(`/samples/${uniqueToken}`)}>
          <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          disabled={!canSeeReport}
          onClick={() => {
            isReview ?
              onSeeReport(cuppingSessionUniqueToken, id) :
              onSeeReport(uniqueToken, id)
          }}
        >
          <MenuIcon><Summarize /></MenuIcon>
          {t('sample.report')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><Archive /></MenuIcon>
          {t('sample.archive')}
        </MenuItem>
        <MenuItem
          disabled={!canDeleteSample}
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id, true))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><DeleteOutline /></MenuIcon>
          {t('common.delete')}
        </MenuItem>
      </Menu>
    </>
  )
})

export const SampleTable = observer((props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    reviewSampleStore: {
      samples, page, totalPage, setValue: setReviewSampleValue,
    },
    dashboardStore: { getSearchCountry },
  } = useStores()

  const sampleList = props.selectedViewMenu === viewSamples[0].label ? samples : props.data;

  const [selectedSamples, setSelectedSamples] = useState<any[]>([])
  const [rowSelection, setRowSelection] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState(() => () => {})
  const [description, setDescription] = useState('')
  const [sampleId, setSampleId] = useState('')
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [countriesOption, setCountriesOptions] = useState({
    code: "",
    label: "",
  })

  const isFirstRender = React.useRef(true)
  const isFirstSelectedRow = React.useRef(false)

  const navigateToSampleDetail = (uniqueToken: string) => navigate(`/samples/${uniqueToken}/details`)

  const handleRightClick = (event, uniqueToken) => {
    event.preventDefault(); // Prevent the default browser context menu
    const url = `/samples/${uniqueToken}/details`;
    if (url) {
      window.open(url, '_blank'); // Open the URL in a new tab
    }
  };

  const renderNavigationWrapper = (info, value, sx?) => {
    return (
      <Box
        onClick={() => navigateToSampleDetail(info.row.original.uniqueToken)}
        onContextMenu={(event)=>handleRightClick(event, info.row.original.uniqueToken)}
        sx={{
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          ...sx
        }}
      >
        {value}
      </Box>
    )
  }

  const renderButtons = (info) => {
    const { uniqueToken } = info.row.original

    return (
      <Stack direction="row" justifyContent="center" spacing={1}>
        {props.isReview && (
          <ApproveRejectButton
            row={info.row.original}
            approveChildren={<Check />}
            onApprove={(hasApproval) => {
              setSampleId(uniqueToken)
              if (hasApproval) setOpenQcReason(true)
              setOpenConfirmApprove(true)
            }}
            rejectChildren={<Clear />}
            onReject={(hasApproval) => {
              setSampleId(uniqueToken)
              if (hasApproval) setOpenQcReason(true)
              setOpenRejectModal(true)
            }}
          />
        )}

        <RenderMenuItems
          row={info.row.original}
          isReview={props.isReview}
          setOpenConfirm={setOpenConfirm}
          setConfirmAction={setConfirmAction}
          setDescription={setDescription}
        />
      </Stack>
    )
  }

  const columns = [
    ...(props.isReview ? [
      columnHelper.accessor('sampleUniqueNumber', {
        id: 'checkboxSampleUniqueNumber',
        enableColumnFilter: false,
        header: () => <Box textAlign="right" ml="30px"></Box>,
        cell: info =>
          <Box>
            <FormControlLabel
              label={""}
              control={
                <Checkbox
                  checked={info.row.getIsSelected()}
                  onChange={info.row.getToggleSelectedHandler()}
                />
              }
              sx={{marginRight: 0}}
            />
          </Box>,
      })
    ] : []),
    ...(props.isReview ? [
      columnHelper.accessor('sampleUniqueNumber', {
        id: 'sampleUniqueNumber',
        header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.samples.id')}</Box>,
        cell: info =>
          renderNavigationWrapper(info, info.renderValue()),
      })
    ] : []),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.samples.name')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
    }),
    columnHelper.accessor('purchaseGrade', {
      id: 'purchaseGrade',
      header: () => <Box textAlign="left" width={120}>{t('sample.purchaseGrade')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.samples.createdOn')}</Box>,
      cell: info => renderNavigationWrapper(info, moment(info.renderValue()).format(formatDate)),
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: () => <Box textAlign="left">{t('sample.samples.species')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('cuppingProtocol', info.renderValue())),
      meta: {
        renderFilterComponent: (info) => (
          <FilterSelect
            value={info.getFilterValue() || ''}
            onChange={(e) => info.setFilterValue(e.target.value)}
            options={specieses}
            translationKey="species" 
          />
        )
      }
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.samples.type')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('sampleType', info.renderValue())),
      meta: {
        renderFilterComponent: (info) => (
          <FilterSelect
            value={info.getFilterValue() || ''}
            onChange={(e) => info.setFilterValue(e.target.value)}
            options={sampleTypes}
            translationKey="sampleType" 
          />
        )
      }
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => <Box textAlign="left">{t('sample.samples.origin')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
      meta: {
        renderFilterComponent: (info) => (
          <AutocompleteSelect
            value={countriesOption}
            onChange={(_, newValue) => {
              info.setFilterValue(newValue?.code || '')
              setCountriesOptions(newValue)
            }}
            getData={getSearchCountry}
        />
        )
      }
    }),
    columnHelper.accessor('processName', {
      id: 'processName',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.samples.process')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.samples.purchaseContract')}</Box>,
      cell: info => <Link to={`/contracts/${info.renderValue()}/details`} >{info.renderValue()}</Link>,
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.samples.salesContract')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('referenceNumber', {
      id: 'referenceNumber',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.referenceNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('grade', {
      id: 'grade',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.sampleGrade')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('supplierName', {
      id: 'supplierName',
      header: () => <Box textAlign="left" sx={{ minWidth: 150 }}>{t('sample.supplier')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('producerName', {
      id: 'producerName',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.producerName')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('strategy', {
      id: 'strategy',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.strategy')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('customer', {
      id: 'customer',
      header: () => <Box textAlign="left" sx={{ minWidth: 120 }}>{t('sample.customer')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('customerCode', {
      id: 'customerCode',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.customerCode')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('receivedOn', {
      id: 'receivedOn',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.receivedOn')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateDispatch', {
      id: 'dateDispatch',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.dateDispatch')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateArrival', {
      id: 'dateArrival',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.dateArrival')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateResults', {
      id: 'dateResults',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.dateResults')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('harvest', {
      id: 'harvest',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.harvest')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('externalIdentification', {
      id: 'externalIdentification',
      header: () => <Box textAlign="left" sx={{ minWidth: 150 }}>{t('sample.externalIdentification')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('sampleReference', {
      id: 'sampleReference',
      header: () => <Box textAlign="left" sx={{ minWidth: 150 }}>{t('sample.sampleReference')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.description')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('varietalsTags', {
      id: 'varietalsTags',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.varietalsTags')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('certification', {
      id: 'certification',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.certification')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('cuppingProtocol', {
      id: 'cuppingProtocol',
      header: () => <Box textAlign="left">{t('sample.cuppingProtocol')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('cuppingProtocol', info.renderValue()) || '-' ),
      meta: {
        renderFilterComponent: (info) => (
          <FilterSelect
            value={info.getFilterValue() || ''}
            onChange={(e) => info.setFilterValue(e.target.value)}
            options={cuppingProtocols}
            translationKey="cuppingProtocol" 
          />
        )
      }
    }),
    columnHelper.accessor('receivedWeight', {
      id: 'receivedWeight',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.receivedWeight')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('cropYear', {
      id: 'cropYear',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.cropYear')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('numberOfBag', {
      id: 'numberOfBag',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.numberOfBags')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('bagWeight', {
      id: 'bagWeight',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.bagWeight')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('moisture', {
      id: 'moisture',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.moisture')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('waterActivity', {
      id: 'waterActivity',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.waterActivity')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('density', {
      id: 'density',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.density')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('temperature', {
      id: 'temperature',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.temperature')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('mass', {
      id: 'mass',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.mass')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.volume')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('warehouseReference', {
      id: 'warehouseReference',
      header: () => <Box textAlign="left" sx={{ minWidth: 150 }}>{t('sample.warehouseReference')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.sampleLocation')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('shipmentDate', {
      id: 'shipmentDate',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.shipmentDate')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('cargoNumber', {
      id: 'cargoNumber',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.cargoSeel')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('containerNumber', {
      id: 'containerNumber',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.containerNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('lotNumberOrIcoMarks', {
      id: 'lotNumberOrIcoMarks',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.lotNumberOrIcoMarks')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('courierName', {
      id: 'courierName',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.courier')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('trackingNumber', {
      id: 'trackingNumber',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.trackingNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('notesAndRemarks', {
      id: 'notesAndRemarks',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.notesAndRemarks')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('latestCuppingSessionSampleCachedAveragedScore', {
      id: 'latestCuppingSessionSampleCachedAveragedScore',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.samples.latestCuppingSessionSampleCachedAveragedScore')}</Box>,
      cell: info => {
        return renderNavigationWrapper(
          info,
          info.renderValue(),
          {justifyContent: "center", fontWeight: "bold"})
      }
    }),
    columnHelper.accessor('averageScore', {
      id: 'averageScore',
      header: () => <Box textAlign="left" sx={{ minWidth: 100 }}>{t('sample.samples.averageScore')}</Box>,
      cell: info => renderNavigationWrapper(
        info,
        (info.renderValue() === 0 || isNull(info.renderValue())) ? "-" : info.renderValue(),
        {justifyContent: "center", fontWeight: "bold"}
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      enableColumnFilter: false,
      header: () => t('sample.samples.action'),
      cell: info => renderButtons(info),
    }),
  ]

  const visibleColumns = React.useMemo(() => {
    const visibleKeys = props.tmpHeaderSelectorSamples
      .filter((item) => item.value)
      .map((item) => item.key);

    if(visibleKeys.length !== 0 ) saveString(HEADER_SELECTOR_SAMPLE_STORAGE_KEY, JSON.stringify(props.tmpHeaderSelectorSamples))

    return columns.filter((col) => visibleKeys.includes(col.id!) || col.id === 'action'); // always show action column
  }, [props.tmpHeaderSelectorSamples]);

  const table = useReactTable({
    data: cloneDeep(sampleList),
    columns: visibleColumns,
    state: { rowSelection },
    enableRowSelection: true,
    manualPagination: true,
    pageCount: totalPage,
    autoResetPageIndex: false,
    onRowSelectionChange: (updater) => {
      setRowSelection(updater) // Correctly updating state
      isFirstSelectedRow.current = true;
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uniqueToken,
  })

  const tableWithSearch = useReactTable({
    data: cloneDeep(sampleList),
    columns: visibleColumns,
    state: { 
      rowSelection, 
      columnFilters: props.columnFilter || [] 
    },
    enableRowSelection: true,
    manualPagination: true,
    pageCount: totalPage,
    onRowSelectionChange: (updater) => {
      setRowSelection(updater) // Correctly updating state
      isFirstSelectedRow.current = true;
    },
	  onColumnFiltersChange: (updaterOrValue) => {
		  setReviewSampleValue('page', 1)
		  props.setColumnFilter(updaterOrValue)
	  },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uniqueToken,
  })

  const getData = () => {
    if(props.selectedViewMenu === viewSamples[0].label){
      props.onGetSamples()
    } else if (props.selectedViewMenu === viewSamples[1].label) {
      props.onGetSamplesWithSearch(props.columnFilter)
    } else {
      return
    }
  }

  useEffect(() => {
    if(isFirstRender.current || !isFirstSelectedRow.current ) return

    if(isEmpty(rowSelection)) {
      setSelectedSamples([])
      props.onSelected([])
  
      // delete sessionStorage
      sessionStorage.removeItem('selectedSamples')
      return
    }

    const tokens = Object.keys(rowSelection)
    const newSelectedSamples = tokens.map(token => {
      let sample = find(selectedSamples, ['uniqueToken', token]) || find(sampleList, ['uniqueToken', token])
      return sample ? clone(sample) : null // Prevent undefined issues
    }).filter(Boolean) // Remove `null` values

    setSelectedSamples(newSelectedSamples)
    props.onSelected(newSelectedSamples)

    // Save row selection to sessionStorage
    sessionStorage.setItem('selectedSamples', JSON.stringify(newSelectedSamples))
  }, [rowSelection])

  useEffect(() => {
    const selectedRow = JSON.parse(sessionStorage.getItem('selectedSamples') || '[]')

    isFirstRender.current = false

    if(isEmpty(selectedRow)) return
    
    const selectedRowToken = selectedRow.reduce((acc, sample) => {
      acc[sample.uniqueToken] = true;
      return acc;
    }, {});

    setRowSelection(selectedRowToken)
    
    setSelectedSamples(selectedRow)
    props.onSelected(selectedRow)
  }, [])

  useEffect(() => {
    const selectedRow = props.reservedSamples.reduce((acc, sample) => {
      acc[sample.uniqueToken] = true;
      return acc;
    }, {});
    
    setRowSelection(selectedRow);
  }, [props.reservedSamples.length])

  useEffect(() => {
   getData()
  }, [page])

  useEffect(() => {
    getData()
  }, [JSON.stringify(props.columnFilter)])

  useEffect(() => {
    getData()
  }, [props.selectedSort])

  useEffect(() => {
    getData()
  }, [props.selectedViewMenu])

  if (sampleList.length === 0 && props.selectedViewMenu === viewSamples[0].label) return <EmptySamples />

  return (
    <>
    <Box sx={{mt: 1}}>
      {props.selectedViewMenu === viewSamples[0].label ? 
        <BasicTable
          table={table}
          page={page}
          totalPage={totalPage}
          onChangePage={(e, p) => setReviewSampleValue('page', p)}
          sx={{ maxHeight: window.innerHeight - 300 }}
          tableProps={{
            stickyHeader: true,
          }}
        /> :
        <FilterRequestTable
          table={tableWithSearch}
          page={page}
          totalPage={totalPage}
          onChangePage={(e, p) => setReviewSampleValue('page', p)}
        />
      }
    </Box>

      <ModalConfirm
        open={openConfirm}
        description={description}
        onClose={() => setOpenConfirm(false)}
        onConfirm={confirmAction}
      />

      <ApproveReject
        id={sampleId}
        isSample
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        onSuccess={props.onGetSamples}
      />
    </>
  )
})
