
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalScoreWrapper: {
      ...centered,
      height: '100%',
      textAlign: 'center',
      flexDirection: 'column',
      margin: `${theme.spacing(1)} 0`
    },
    legend: {
      fontStyle: 'italic',
      '&.bold': {
        fontWeight: 500
      }
    },
    approved: {
      color: colors.tertiary.main,
      textTransform: 'uppercase'
    },
    rejected: {
      color: colors.error.primary,
      textTransform: 'uppercase'
    },
    powered: {
      ...centered,
      '&.absolute': {
        position: 'absolute',
        right: 10,
        bottom: 5
      }
    },
    wheelWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
	    alignItems: 'center',
    },
    wheel: {
      width: '100%',
      height: '100%',
      '@media print': {
        width: '310px !important',
        height: '310px !important',
        margin: '0 auto'
      }
    },
    tableDefect: {
      '& th, td': {
        fontSize: '0.75rem'
      },
      '& tfoot > tr > td': {
        borderBottom: 'none'
      }
    },
    primary: {
      color: colors.primary.main
    },
    cellMobile: {
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5),
    },
    avgIntensitiesWrapper: {
      color: colors.primary.main,
      textAlign: 'left',
      fontSize: "small",
      border: '1px solid #8347AD',
      borderRadius: 8,
      padding: 8,
      height: 'fit-content'
    },
  })
)